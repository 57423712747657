@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-left: 1px solid #ced2e5;
  padding-left: 24px;
  min-height: 50vh;
  width: 100%;

  @include small-screen {
    border-left: none;
    padding-left: 0px;
  }
}

.empty {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.components {
  display: flex;
  gap: 24px; /* Spacing between items */
  padding: 16px;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  &.more-than-4 {
    justify-content: space-around;
  }

  @include tablet {
    margin-top: 24px;
    justify-content: flex-start;
  }
}

.loading {
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include phone {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.tools {
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;

  @include phone {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.removeAll {
  @include reset-button;
}

.post {
  background-color: $secondary !important;
  color: $primary !important;
  font-weight: 700;
  border-radius: 4px;
  border: none;

  &:hover {
    color: $primary !important;
    background-color: $primary-hover !important;
  }
}

.addItem {
  width: 220px;
  height: 220px;
  border: 1px solid #8e53e8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.centerButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8e53e8;
  font-weight: 500;
  font-size: 12px;
}
