@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eff0f5;
  border-radius: 12px;
  padding: 16px;
}

.entireWrapper {
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 62px;
  top: 24px;
  right: 45px;
  z-index: 50;

  &.dashboardNavbar {
    box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
    height: 140px;
    padding: 12px;
    top: 12px;
    left: 50px;
    right: 50px;
  }
}

.actions {
  color: $primary;
  background-color: $secondary;
  border: none;
  display: flex;
  align-items: center;
  font-weight: 700;
  border-radius: 4px;

  &:hover {
    color: white !important;
    background-color: $primary !important;
  }
}

.searchForm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
}

.menu {
  display: flex;
  align-items: center;
  gap: 4px;
}

.menuItem {
  color: #292929 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 4px 10px;
  line-height: 120%;

  &.active {
    color: $primary !important;
  }
}

.post {
  background-color: $primary;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  border: none;

  &:hover {
    color: white !important;
    background-color: $primary-hover !important;
  }
}

.dropdownButton {
  background: none;
  border: 1px solid #ced2e5;
  border-radius: 6px;
  height: 38px;

  &:hover {
    background: white !important;
    color: black;
  }
}

.modalItem {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  margin-bottom: 12px;
  justify-content: center;
}

.filterSwitch {
  border: 1px solid #ced2e5;
  border-radius: 6px;
  padding: 3px;
  background-color: #eff0f5;
}

.filter {
  border-bottom: 1px solid #ced2e5;
  padding-bottom: 16px;
}

.checkboxes {
  border-bottom: 1px solid #ced2e5;
  margin-bottom: 16px;
}

.rentSelect {
  height: 40px;
  border-radius: 6px;
  min-width: 240px !important;
}
.typeSelect {
  height: 40px !important;
  border-radius: 6px;
}

.width120 {
  min-width: 190px !important;
}
.width220 {
  min-width: 350px !important;
}
.width320 {
  min-width: 400px !important;
}
.width420 {
  min-width: 480px !important;
}

.width520 {
  min-width: 610px !important;
}

.bedSelect {
  height: 40px;
  border-radius: 6px;
  min-width: 190px !important;
}
.searchInput {
  background-color: white;
  border-radius: 6px;
  flex-grow: 1 !important;
  height: 38px !important;
  min-width: 220px;
}

.cancel {
  width: 80%;
  height: 50px;
  padding: 24px;
  font-size: 14px;
  color: #8e53e8;
  background-color: #ebddff;
  border-radius: 6px;
  border: none;

  &:hover {
    color: white !important;
    background-color: #6a2dc7 !important;
  }
}
.result {
  width: 80%;
  height: 50px;
  padding: 24px;
  font-size: 14px;
  color: white;
  border-radius: 6px;
  border: none;

  &:hover {
    color: white !important;
    background-color: #6a2dc7 !important;
  }
}

.uploadedImage {
  border-radius: 50%;
}

:global {
  .ant-dropdown-placement-bottom {
    width: 400px;
  }
  .ant-radio-button-wrapper {
    background-color: #eff0f5;
    border: none;
    color: #8e53e8;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 142.857% */
    letter-spacing: 0.84px;
    text-transform: uppercase;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #eff0f5;
  }

  .ant-radio-button-wrapper:first-child {
    border-inline-start: none;
    border-start-start-radius: 8px;
    border-end-start-radius: 8px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #eff0f5;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background: none;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: black !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    letter-spacing: 0.84px !important;
    text-transform: uppercase !important;
    background: #fff !important;
    border: none !important;
    border-radius: 4px !important;
  }
}
