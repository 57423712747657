@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  padding: 22px;
  border-radius: 12px;
  background: #fff;
  text-align: center;
  padding-top: 124px;

  @include small-screen {
    padding: 94px 0 0 0;
  }

  @include phone {
    padding: 94px 16px 0;
  }
}

.title {
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 72px; /* 120% */
  letter-spacing: -1.2px;
}

.img {
  width: 90%;
  margin-top: 36px;
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.iconWrapper {
  min-width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment {
  line-height: 160%;
  @include phone {
    font-size: 16px;
  }
}

.content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  width: 60%;

  @include small-screen {
    width: 90%;
    margin-top: 16px;
  }
}

.itemWrapper {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  gap: 12px;
  border-bottom: 1px solid #ced2e5;
  padding-bottom: 24px;

  @include phone {
    grid-template-columns: 1fr;
  }
}

.loading {
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricingCardWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: center;
  display: flex;
  width: 100%;
  gap: 16px;

  @include phone {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.pricingCard {
  border-radius: 12px;
  border: 1px solid #ced2e5;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
  padding: 16px 8px 16px 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 220px;
  width: 160px;
  justify-content: space-between;

  @include phone {
    margin-left: auto;
    margin-right: auto;
  }
}
