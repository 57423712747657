@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-left: 1px solid #ced2e5;
  padding-left: 48px;
  min-height: 50vh;
  width: 80%;

  @include normal-screen {
    width: 100%;
  }

  @include small-screen {
    border-left: none;
    padding-left: 0px;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.adminItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.propertyImage {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  display: block;
}

.price {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.iconWrapper {
  min-width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  width: 80%;
  gap: 12px;
  border-bottom: 1px solid #ced2e5;
  padding-bottom: 24px;

  @include phone {
    grid-template-columns: 1fr;
  }
  @include normal-screen {
    width: 100%;
  }
}

.loading {
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricingCardWrapper {
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  width: 80%;
  justify-content: space-evenly;

  @include normal-screen {
    width: 100%;
  }

  @include phone {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

.pricingCard {
  border-radius: 12px;
  border: 2px solid #ced2e5;
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.16);
  padding: 24px 12px 12px 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 160px;
  justify-content: space-between;

  @include phone {
    margin-left: auto;
    margin-right: auto;
  }
}

.invitationWrapper {
  padding: 43px 40px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: #eff0f5;
  border-radius: 12px;
}

.address {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
}

.editButton {
  position: absolute;
  left: 8px;
  top: 10px;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px;
  z-index: 30;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.deleteButton {
  position: absolute;
  left: 180px;
  top: 10px;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 8px;
  z-index: 30;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.label {
  max-width: 210px;

  @include phone {
    max-width: 160px;
  }
}

.propertyLink {
  text-decoration: none;
  display: flex;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;

  @include phone {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.propertyLink.active {
  background-color: $grey-light-1;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;

  @include phone {
    display: none;
  }
}

.mobileInfo {
  display: none;

  @include phone {
    display: block;
  }
}

.imageCount {
  position: absolute;
  left: 4px;
  bottom: 8px;
  color: white;
  border-radius: 8px;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2px 4px;
  height: 24px;
  z-index: 30;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rentSelect {
  height: 40px;
  border-radius: 6px;
  min-width: 240px !important;
}
