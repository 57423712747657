@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  justify-self: center;
  margin: 8px 0;
  border-top: 1px solid #ced2e5;
  padding-top: 8px;
  width: 100%;

  @include phone {
    border-top: none;
    padding-bottom: 0px;
    margin: 0;
    width: fit-content;
    height: fit-content;
  }
}

.propertyImage {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  display: block;
}

.price {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.address {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
}

.editButton {
  position: absolute;
  left: 8px;
  top: 10px;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px;
  z-index: 30;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.deleteButton {
  position: absolute;
  left: 180px;
  top: 10px;
  color: white;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 8px;
  z-index: 30;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.label {
  max-width: 210px;

  @include phone {
    max-width: 160px;
  }
}

.propertyLink {
  text-decoration: none;
  display: flex;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  @include phone {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.propertyLink.active {
  background-color: $grey-light-1;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;

  @include phone {
    display: none;
  }
}

.mobileInfo {
  display: none;

  @include phone {
    display: block;
  }
}

.imageCount {
  position: absolute;
  left: 4px;
  bottom: 8px;
  color: white !important;
  border-radius: 8px;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2px 4px;
  height: 24px;
  z-index: 30;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}
