@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  padding-top: 120px;
  margin-left: 100px;
  margin-right: 90px;
  padding-bottom: 48px;
  min-height: calc(100vh - 120px);

  @include tablet {
    margin: 90px 16px 48px;
  }
}

.form {
  border: 1px solid #ced2e5;
  border-radius: 12px;
  margin-top: 32px;
  padding: 24px;
  width: 100%;
  overflow: auto;

  @include phone {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
}

.devItem {
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  margin-bottom: 12px;
  justify-content: center;
  margin-top: 24px;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 24px;

  @include phone {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.dropDownWrapper {
  min-width: 100%;

  @include phone {
    min-width: 100%;
  }
}

.devItemStart {
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: flex-start;
  margin-bottom: 12px;
  justify-content: center;
  margin-top: 24px;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 24px;

  @include phone {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.comments {
  border: 1px solid #ced2e5 !important;
  background-color: white;
  margin-top: 12px;
}

.uploadedImage {
  border-radius: 6px;
}

.deleteButton {
  right: 10px;
  top: 10px;
  width: 36px;
  position: absolute;
  color: white !important;
  font-weight: 700;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.deleteDocButton {
  right: 8px;
  top: 8px;
  position: absolute;
  color: white !important;
  font-weight: 700;
  width: 24px;
  height: 24px;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2px 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
}

.uploadedDocFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
  border: none;
  background-color: #e7e9f1;
  padding: 8px 16px;
  border-radius: 8px;
}


.upload {
  border-radius: 16px;
  border: 1px dashed #8e8888;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  margin-top: 8px;
}

.uploadBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.uploadedFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
  border: none;
}

.chooseButton {
  padding: 10px 36px !important;
  background-color: #e7e9f1 !important;
  color: #7f86a4 !important;
}

.uploadWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.Submit {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.phoneContainer {
  width: 100%;
  background-color: #fff !important;
}

.phoneButton {
  background-color: transparent !important;
  border: none !important;

  div {
    &:hover,
    :focus {
      background-color: transparent !important;
    }
  }
}

.cardsWrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  gap: 16px;
  width: 100%;
}
