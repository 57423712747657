@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  border-radius: 12px;
  padding: 12px 0;

  @include phone {
    width: 100%;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.imageWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
}

.other {
  border: 1px solid #ced2e5 !important;
  margin-top: 16px;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-top: 40px;
}

.agenciesImage {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.infoWrapper {
  border-top: 1px solid #ced2e5;
}
