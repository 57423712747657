@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  margin-top: 120px;
  margin-left: 90px;
  margin-right: 90px;
  padding-top: 24px;
  padding-bottom: 48px;

  @include tablet {
    margin: 70px 32px 48px;
  }

  @include phone {
    margin: 80px 24px 48px;
  }
}

.Label {
  font-size: 16px;
  margin-top: 12px;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-top: 16px;

  @include phone {
    flex-direction: column;
    align-items: start;
    gap: 0px;
  }
}

.actionButtonGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @include phone {
    flex-direction: column;
    align-items: start;
    gap: 0px;
  }
}

.linkButton {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
  color: $primary;
  border: none;
  box-shadow: none;
  padding: 0;
}

.content {
  padding-top: 24px;
  margin-top: 12px;
  border-top: 1px solid #ced2e5;
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @include small-screen {
    flex-direction: column;
  }

  @include phone {
    gap: 0;
  }
}

.imageGallery {
  flex-grow: 0.5;
  overflow-x: auto;
}

.agent {
  min-width: 420px;

  @include phone {
    min-width: 100%;
  }
}

.field {
  margin: 48px 0;

  @include phone {
    margin: 24px 0;
  }
}

.parameter {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid #ced2e5;
  margin: 12px 0;
}

.parameterlabel {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  color: #7f86a4;
}

.fieldTitle {
  margin-bottom: 16px;
}

.infoLocation {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  @include phone {
    padding-right: 0;
  }

  &:nth-child(2) {
    margin-top: 24px;
  }
}

.mapWrapper {
  width: 100%;
  height: 500px;

  position: relative;

  @include tablet {
    height: 400px;
  }

  @include phone {
    height: 400px;
  }
}

.specialParameter {
  display: flex;
  align-items: center;
  gap: 16px;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
  }
}

.changeButton {
  padding: 0.25rem; /* Equivalent to px-1 */
  position: absolute;
  width: 29px;
  bottom: 22rem; /* Equivalent to bottom-56 */
  right: 11px; /* Equivalent to right-2.5 */
  background-color: white;
  border: 1px solid #d1d5db; /* border-secondary-content, typically a light gray */
  z-index: 10;
  border-radius: 4px;

  &:hover {
    border-color: #6b7280; /* hover:border-neutral, typically a darker gray */
    background-color: #f3f4f6;
  }

  @include phone {
    bottom: 24rem;
  }
}

.interesting {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    height: 0;
    display: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $grey-light-1;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $grey-light-2;
  }
  @include tablet {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  @include phone {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.iconWrapper {
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: #8e53e8;
  text-decoration: none;
}

.modalComment {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.shareButtonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-top: 24px;
}

.shareButton {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
}

.netWorkShareButton {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  gap: 8px;
  border: 1.5px solid #ced2e5 !important;
  border-radius: 8px !important;
  height: 50px;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px !important; /* 130% */
  letter-spacing: -0.4px !important;
  padding: 16px !important;

  &:hover {
    color: #8e53e8 !important; // Example: change text color on hover
    border-color: #8e53e8 !important;
    cursor: pointer; // Change cursor to indicate clickability
    transition: all 0.3s ease; // Smooth transition for all properties
  }
}

.shareIconWrapper {
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}
