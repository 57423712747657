@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.landingWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  @include small-screen {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px 0 48px;

  @include phone {
    padding: 0 22px;
  }
}

.buttonGroup {
  display: flex;
  gap: 24px;
  margin-top: 24px;
  width: 70%;

  @include tablet {
    width: 100%;
  }

  @include phone {
    flex-direction: column;
    margin-bottom: 24px;
    gap: 8px;
  }
}

.firstButton {
  width: 100%;
  height: 50px;
  padding: 24px;
  font-size: 14px;
  color: white;
  border: none;
  background-color: #8e53e8;

  &:hover {
    color: white !important;
    background-color: #6a2dc7 !important;
  }
}

.secondButton {
  width: 100%;
  height: 50px;
  padding: 24px;
  font-size: 14px;
  color: #8e53e8;
  background-color: #eff0f5;
  border: none;

  &:hover {
    color: white !important;
    background-color: #6a2dc7 !important;
  }
}

.landingTitle {
  font-size: 80px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 110% !important;
  letter-spacing: -1.6px !important;

  @include small-screen {
    margin-top: 24px;
    font-size: 48px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: 54px !important;
    letter-spacing: -1.92px !important;
  }
}

.logo {
  width: 240px;
  height: 40px;

  @include small-screen {
    width: 220px;
    height: 40px;
  }
}

.list {
  font-size: 20px;
  margin-bottom: 8px;
  @include small-screen {
    font-size: 16px;
    margin-bottom: 4px;
  }
}

.subTitle {
  font-size: 24px;
  margin-bottom: 16px;
  @include small-screen {
    font-size: 20px;
    margin-bottom: 16px;
  }
}
