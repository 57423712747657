@import 'src/styles/mixins.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  @include phone {
    flex-direction: column;
    gap: 16px;
  }
}

.actionGroup {
  display: flex;
  gap: 8px;
  align-items: center;
  @include phone {
    justify-content: space-between;
    width: 100%;
  }
}

.sizing {
  display: flex;
  align-items: center;
  gap: 12px;
}
