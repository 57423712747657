@import 'src/styles/variables.scss';

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  min-width: 200px;
  width: fit-content;
  border-radius: 6px;
  color: #202025 !important;

  &.transparent {
    background: transparent !important;
    outline: none !important;
    border: none !important;

    :global {
      .ant-select-selector {
        background: none !important;
        padding: 0;
      }

      .ant-select-selection-item {
        font-weight: 600;
      }
    }
  }
}

.fullWidth {
  width: 100%;
}

:global {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }

  .ant-select-arrow {
    color: $color-simple-secondary;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #dbebff !important;
    font-weight: 400 !important;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #202025 !important;
  }

  .ant-select-dropdown {
    padding: 0 !important;
    border: 1px solid #a3a3bf3d !important;
    box-shadow: 0 32px 64px 0 #0c003029 !important;
    border-radius: 8px !important;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: $primary-txt !important;
  }

  .ant-select-selection-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  .ant-select.ant-select-in-form-item {
    height: 100%;
    min-height: 40px;
  }

  .ant-select-selector {
    height: 100% !important;
    background: white !important;
    border-radius: 6px !important;
    border: 1px solid #ced2e5 !important;
    box-shadow: none !important;
  }

  .ant-select-item {
    padding: 8px !important;
  }

  .ant-select-item-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .ant-select-item-option-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .ant-select-clear {
    margin-right: 4px;
  }
}

.selectedIcon {
  margin-top: calc(50% - 5px);
  height: 10px;
  color: $primary-bg;
}

.suffixIcon {
  height: 18px;
}

.label {
  margin-bottom: 6px;
  margin-left: 2px;
}

.large {
  height: 50px !important;
}
