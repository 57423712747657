@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.form {
  background-color: $white;
  padding: 32px;
  border-radius: 16px;
  min-width: 420px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  position: relative;
  border: 1px solid #ced2e5;
  height: 100%;

  @include small-screen {
    width: 100%;
    min-width: 240px;
    padding: 20px;
    border: none;
  }

  @include tablet {
    width: 100%;
    min-width: 240px;
    gap: 14px;
    border: none;
  }

  @include phone {
    width: 100%;
    min-width: 240px;
    padding: 20px;
    border: none;
  }
}

.text {
  font-size: 36px;
  font-family: Inter, sans-serif;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.phone {
  display: flex;
}

.phoneContainer {
  width: 100%;
}

.phoneButton {
  background-color: transparent !important;
  border: none !important;

  div {
    &:hover,
    :focus {
      background-color: transparent !important;
    }
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}

:global {
  .react-tel-input .form-control {
    color: $grey-dark-2;
    border-radius: $border-radius-button;
    //padding: 0 14px;
    background: $tertiary-bg !important;
    border: 1px solid transparent;
    transition: all 0.3s ease-out;
    box-shadow: none !important;
    height: 44px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    font-family: $font-family--default;
    width: 100%;

    &:focus {
      border-color: #b989ff !important;
      outline: rgba(91, 77, 255, 0.16) solid 3px !important;
    }
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: transparent !important;
  }
}

.privacy {
  color: $grey-dark-2;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  margin-left: auto;
  margin-right: auto;
}

.termsLink {
  font-weight: 700;
  margin: 0 4px;
  cursor: pointer;
  color: #8e53e8;

  &:hover {
    color: #b989ff;
  }

  &:last-child {
    margin-right: 0;
  }
}

.wrapper {
  width: 100%;
  padding: 24px;

  @include small-screen {
    padding: 0;
  }
}

.logo {
  display: none;

  @include tablet {
    display: block;
  }
}

.block {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
}

.hint {
  border-radius: 4px;
  background: #e7e9f1;
  backdrop-filter: blur(10px);
  display: flex;
  padding: 3px 8px;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  color: #7f86a4;

  &.invalid {
    background: #ff4b53;
    color: white;
  }

  &.valid {
    background: #c3f5eb;
    color: #02c29a;
  }
}

.backLink {
  color: #8e53e8;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
}
