@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  padding: 22px;
  border-radius: 12px;
  background: #fff;
  text-align: center;
  padding-top: 144px;

  @include small-screen {
    padding: 94px 0 0 0;
  }

  @include phone {
    padding: 94px 16px 0;
  }
}

.title {
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 72px; /* 120% */
  letter-spacing: -1.2px;
}
.aboutText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}
.img {
  width: 60%;
  margin-top: 36px;

  @include phone {
    width: 100%;
    margin-top: 16px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.iconWrapper {
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-top: 48px;
  width: 50%;

  @include small-screen {
    width: 80%;
  }

  @include phone {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 32px;
  }
}

.comment {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  @include phone {
    font-size: 18px;
  }
}
.components {
  display: flex;
  gap: 24px; /* Spacing between items */
  padding: 16px;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.loading {
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
